<template>
  <div class="main-container clear">
    <div
      class="video-left f-left"
      :style="{ width: mock_live_url ? '100%' : '80%' }"
    >
      <video
        id="videoplay"
        ref="videoplay"
        class="video video-js vjs-default-skin"
        v-on:play="onPlay"
        autoplay
        controls
        controlsList="nodownload"
      >
        <source :src="mock_live_url" />
      </video>
      <div class="tags" v-if="!mock_live_url">
        <div class="chapter_catalog" @click="changeTag(1)">
          <img src="@/assets/images/chapter_ catalog@2x.png" />
          <p>大纲</p>
        </div>
        <div class="chapter_ask" @click="changeTag(2)">
          <img src="@/assets/images/chapter_ask@2x.png" />
          <p>问答</p>
        </div>
      </div>
    </div>
    <div class="chapters" v-if="!mock_live_url">
      <CourseChapter
        class="video"
        v-if="current == 1"
        :course="course"
        :currentPeriod="period_id"
      ></CourseChapter>
      <ChapterQuestion class="question" v-if="current == 2"></ChapterQuestion>
    </div>
  </div>
</template>
<script>
import { totp } from "otplib";
import md5 from "md5";
import Hls from "hls.js";
import CourseChapter from "../../components/CourseChapter.vue";
import ChapterQuestion from "../../components/ChapterQuestion.vue";

function getSecret(url, unixTime) {
  const userId = localStorage.getItem("user_id");

  return md5(`${unixTime}-${userId}-${url}`);
}

function getSecretTime(currentTime) {
  return currentTime - (2 << 9);
}

function getSealedUrl(url) {
  const userId = localStorage.getItem("user_id");
  const currentTime = Math.floor(Date.now() / 1000);
  const secretTime = getSecretTime(currentTime);
  const token = totp.generate(getSecret(url, secretTime));
  const sealedUrl = `${url}?token=${token}&t=${currentTime}&u=${userId}`;
  return sealedUrl;
}

export default {
  name: "Video",
  components: {
    CourseChapter,
    ChapterQuestion,
  },
  data() {
    return {
      course_type: "",
      course_id: "",
      period_id: "",
      video_id: "",
      course: {},
      current: 1,
      video_url: "",
      mock_live_url: "",
    };
  },
  mounted() {
    const mock_live_url = this.$route.query.mlurl;

    if (mock_live_url) {
      if (!localStorage.studentToken) {
        this.$message.warning("请先登录");
        this.$router.push({
          name: "Login",
        });
        return;
      }

      document.oncontextmenu = () => false;
      this.mock_live_url = getSealedUrl(mock_live_url);

      return;
    }

    this.video_url = this.$route.query.video_url;
    if (this.video_url) {
      if (!localStorage.studentToken) {
        this.$message.warning("请先登录");
        this.$router.push({
          name: "Login",
        });
        return;
      }

      const hls = new Hls();
      hls.loadSource(this.video_url);
      hls.attachMedia(this.$refs.videoplay);
    }
  },
  async created() {
    this.init();
    await this.play();
  },
  methods: {
    init() {
      this.course_id = this.$route.query.course_id;
      this.course_type = this.$route.query.course_type;
      this.period_id = this.$route.query.period_id.toString();
      this.video_id = this.$route.query.video_id;
      this.course = { id: this.course_id, course_type: this.course_type };
      this.video_url = this.$route.query.video_url;
      this.mock_live_url = this.$route.query.mlurl;
    },
    onPlay() {
      const mock_live_url = this.$route.query.mlurl;
      if (mock_live_url) {
        this.mock_live_url = getSealedUrl(mock_live_url);
      }
    },
    async play() {
      if (this.video_url) {
        return;
      }

      if (this.mock_live_url) {
        return;
      }

      if (this.course_type == 5 || this.course_type == 8) {
        if (this.video_id == "") {
          return;
        }
        let data = await this.$http.get(
          "/api/app/getPlayToken/video_id=" +
            this.video_id +
            "/course_id=" +
            this.course_id
        );
        this.$refs.videoplay.innerHTML = "";
        new window.BjcPlayer(this.$refs.videoplay, {
          token: data.token,
          vid: data.video_id,
          privateDomainPrefix: "b41634072",
          user_name: data.user_name || "游客", // 用户名， 主要用于数据统计
          user_number: data.user_id || 9999999, // 用户id， 主要用于数据统计
          autoplay: true,
        });
        console.log({
          token: data.token,
          vid: data.video_id,
          privateDomainPrefix: "b41634072",
          user_name: data.user_name || "游客", // 用户名， 主要用于数据统计
          user_number: data.user_id || 9999999, // 用户id， 主要用于数据统计
          autoplay: true,
        });
      }
    },
    changeTag(flag) {
      this.current = flag;
    },
  },
  watch: {
    $route: async function () {
      this.$router.go(0);
      this.init();
      await this.play();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";
.main-container {
  // padding: 30px;
  width: 100%;
  height: 100%;
}
.video-left {
  width: 80%;
  position: relative;
}
#videoplay {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.chapters {
  background-color: white;
  padding: 20px;
  // width: 310px;
  // min-width: 310px;
  margin-left: 80%;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  /deep/ .chapter-content.current .period-title {
    color: $primary-color;
  }
}
.tags {
  top: 100px;
  right: 0;
  position: absolute;
  text-align: center;
  z-index: 2;
  font-size: 12px;
  .chapter_catalog,
  .chapter_ask {
    width: 48px;
    height: 58px;
    background: $primary_color;
    color: #fff;
    cursor: pointer;
    img {
      width: 17px;
      height: 14px;
      margin-top: 12px;
    }
  }
  .chapter_ask {
    margin-top: 8px;
    background-color: #fff;
    color: $primary_color;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
