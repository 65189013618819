<template>
  <div class="question">
    <!-- 列表 -->
    <div v-if="type == 1">
      <el-input
        placeholder="请输入标题"
        v-model="params.title"
        maxlength="50"
      />
      <el-input
        class="contnt"
        type="textarea"
        :rows="3"
        maxlength="300"
        v-model="params.content"
        placeholder="提出你的问题，会有老师来专门解答哦！"
      >
      </el-input>
      <div class="images">
        <div>
          <el-popover
            popper-class="ask_popper_class"
            placement="bottom-start"
            title="本地上传"
            :popper-options="options"
            v-model="showPopover"
            trigger="click"
          >
            <div>
              <p class="img_num">
                共{{ fileList.length }}张，还可上传{{ 9 - fileList.length }}张
              </p>
              <el-upload
                :action="url"
                :headers="headers"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-success="handleAvatarSuccess"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <p slot="reference" class="reference">
              <span class="el-icon-picture-outline"></span>
              <span>图片</span>
            </p>
          </el-popover>
        </div>
        <div class="answer_btn">
          <el-button type="primary" @click="submit()">提交</el-button>
        </div>
      </div>
      <div class="question-box">
        <div v-for="(item, index) in list" :key="index">
          <div class="question-item line" @click="showDetail(item)">
            <div class="item_title">
              <div class="question-item-left">
                <img :src="item.avatar" />
              </div>
              <div class="question-item-right">
                <h3 class="question-item-name">
                  {{ item.nickname }}
                </h3>
                <p class="created_at">{{ item.created_at }}</p>
                <p class="question-item-title question-line-clamp">
                  {{ item.ask_title }}
                </p>
                <div class="answer_state">
                  <span v-if="item.answer_number > 0" class="is_answerd"
                    >已回答</span
                  >
                  <span v-else class="no_answer">未回答</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Empty
          v-if="list.length == 0"
          :tbPadding="50"
          class="empty"
          title="暂无提问！"
        />
        <el-pagination
          background
          small
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="limit"
          layout="prev, pager, next, total, jumper"
          :total="total"
          v-if="total > limit"
        ></el-pagination>
      </div>
    </div>
    <!-- 详情 -->
    <div v-if="type == 2" class="detail">
      <p class="back" @click="back">
        <span class="el-icon-arrow-left"></span>
        <span>返回</span>
      </p>
      <!-- 问题本身 start -->
      <div class="question-box">
        <div class="question-item detail_info line">
          <div class="item_title">
            <div class="question-item-left">
              <img :src="detail.avatar" />
            </div>
            <div class="question-item-right">
              <h3 class="question-item-name">
                {{ detail.nickname }}
              </h3>
              <p class="created_at">{{ detail.created_at }}</p>
              <div class="like_state" @click.stop="clickLike(0, detail)">
                <img
                  v-if="!detail.is_like"
                  src="@/assets/images/news/dianzan@3x.png"
                />
                <img v-else src="@/assets/images/news/dianzan_active.png" />
                {{ detail.like_number }}
              </div>
            </div>
          </div>
          <div class="question-item-right w280">
            <p class="question-item-title">
              <img class="tips" src="@/assets/images/question@2x.png" />
              {{ detail.title }}
            </p>
            <p class="question-item-content" v-html="detail.content"></p>
          </div>
        </div>
      </div>
      <!-- 问题本身 end -->
      <!-- 回答列表 start -->
      <div class="question-box">
        <div v-for="(item, index) in detailList" :key="index">
          <div class="question-item">
            <div class="item_title">
              <div class="question-item-left">
                <img :src="item.avatar" />
              </div>
              <div class="question-item-right">
                <h3 class="question-item-name">
                  {{ item.user_name }}
                </h3>
                <p class="created_at">{{ item.created_at }}</p>
                <div class="like_state" @click.stop="clickLike(1, item)">
                  <img
                    v-if="!item.is_like"
                    src="@/assets/images/news/dianzan@3x.png"
                  />
                  <img v-else src="@/assets/images/news/dianzan_active.png" />
                  {{ item.like_number }}
                </div>
              </div>
            </div>
            <p class="question-item-content">
              <img class="tips" src="@/assets/images/answer@2x.png" />
              <span v-html="item.content"></span>
            </p>
            <el-button
              size="small"
              class="more_ask"
              type="primary"
              @click="reApply(item)"
              >追问</el-button
            >
            <div style="margin: 10px 0" v-if="item.show_reAsk">
              <el-input type="textarea" v-model="more_ask"></el-input>
              <el-button
                size="small"
                style="float: right; margin-top: 17px; width: 74px"
                type="primary"
                @click="submitreApply(item)"
                >追问</el-button
              >
            </div>
            <!-- 回答子列表 start -->
            <div
              class="question-box child_ask"
              :class="item.show_reAsk ? 'mt' : ''"
              v-if="item.child.data.length > 0"
            >
              <div
                class="question-item"
                v-for="(child, i) in item.child.data"
                :key="i"
              >
                <div class="item_title">
                  <div class="question-item-left">
                    <img :src="child.avatar" />
                  </div>
                  <div class="question-item-right">
                    <h3 class="question-item-name">
                      {{ child.user_name }}
                    </h3>
                    <p class="created_at">{{ child.created_at }}</p>
                    <p class="question-item-title">
                      {{ child.title }}
                    </p>
                    <p class="question-item-content">
                      <span v-if="child.ait"
                        >回复
                        <span class="ait">@{{ child.ait }}</span>
                      </span>
                      <span v-html="child.content"></span>
                    </p>
                    <div class="like_state" @click.stop="clickLike(1, child)">
                      <img
                        v-if="!child.is_like"
                        src="@/assets/images/news/dianzan@3x.png"
                      />
                      <img
                        v-else
                        src="@/assets/images/news/dianzan_active.png"
                      />
                      {{ child.like_number }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              small
              @current-change="
                (val) => {
                  handleChildChange(val, item, index);
                }
              "
              :current-page="item.child.current_page"
              :page-size="childLimit"
              layout="prev, pager, next"
              v-if="item.child.total > childLimit"
              :total="item.child.total"
            ></el-pagination>
            <Empty
              v-if="item.child.data.length == 0"
              :tbPadding="50"
              class="empty"
              title="暂无内容！"
            />
            <!-- 回答子列表 end -->
          </div>
        </div>
        <Empty
          v-if="detailList.length == 0"
          :tbPadding="50"
          class="empty"
          title="暂无回答！"
        />
        <el-pagination
          background
          small
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="limit"
          layout="prev, pager, next, total, jumper"
          :total="total"
          v-if="total > limit"
        ></el-pagination>
      </div>
      <!-- 回答列表 end -->
    </div>
  </div>
</template>
<script>
const buildConfig = require("../../../build/" + process.env.BUILD_ENV + ".js");
export default {
  props: {},
  data() {
    return {
      url: buildConfig.BASE_URL + "/api/app/public/img",
      showPopover: false,
      options: { boundariesElement: ".images" },
      headers: { Authorization: "Bearer " + localStorage.studentToken },
      fileList: [],
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      params: {
        title: "",
        content: "",
      },
      type: 1,
      detailList: [],
      detail: {},
      more_ask: "",
      answer_id: "",
      childLimit: 5,
    };
  },
  async created() {
    this.getQuestionList();
    this.params.course_id = this.$route.query.course_id;
    this.params.course_periods_id = this.$route.query.id;
    this.params.course_chapter_id = this.$route.query.parent_id;
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.data.path);
    },
    handleRemove(file, fileList) {
      this.fileList.forEach((item, index) => {
        if (item == file.response.data.path) {
          this.fileList.splice(index, 1);
        }
      });
    },
    handleCurrentChange(page) {
      this.page = page;
    },
    getChildAnswer(_id, _page) {
      let data;
      this.$http
        .get("/api/app/ask/caskChildDetailList", {
          id: _id,
          page: _page,
          limit: this.childLimit,
        })
        .then((res) => {
          data = res;
        });
      return data;
    },
    handleChildChange(page, item, index) {
      this.$http
        .get("/api/app/ask/caskChildDetailList", {
          id: item.id,
          page: page,
          limit: this.childLimit,
        })
        .then((res) => {
          let fData = this.detailList[index];
          fData.child.data = res.data;
        });
    },
    getQuestionList() {
      let params = {
        course_id: this.$route.query.course_id,
        course_periods_id: this.$route.query.period_id,
        page: this.page,
      };
      this.$http.get("/api/app/ask/caskList", params).then((res) => {
        this.total = res.total;
        this.list = res.list;
      });
    },
    submit() {
      if (this.params.title == "") {
        this.$message.warning("请填写提问标题");
        return false;
      } else if (this.params.content == "") {
        this.$message.warning("请填写提问内容");
        return false;
      } else {
        if (this.fileList.length > 0) {
          this.params.images = this.fileList.join(",");
        }
        this.$http.post("/api/app/ask/cask", this.params).then((res) => {
          this.getQuestionList();
          this.fileList = [];
          this.params.title = "";
          this.params.content = "";
          this.$message.success("您的问题已提交成功");
        });
      }
    },
    getAskDetail(item) {
      //获取问题信息
      this.$http
        .get(`/api/app/ask/caskDetail`, {
          course_id: this.$route.query.course_id,
          id: item.id,
        })
        .then((res) => {
          this.detail = res;
        });
    },
    getAnswerList(item) {
      //获取回答列表
      this.$http
        .get(`/api/app/ask/caskDetailList`, {
          id: this.answer_id,
          limit: 10,
          childLimit: 5,
        })
        .then((res) => {
          this.detailList = res.data;
          this.type = 2;
        });
    },
    showDetail(item) {
      this.getAskDetail(item);
      this.answer_id = item.id;
      this.getAnswerList();
    },
    back() {
      this.type = 1;
    },
    submitreApply(item) {
      if (this.more_ask == "") {
        this.$message.warning("请填写追问内容");
        return;
      }
      let params = { parent_id: item.id, content: this.more_ask };
      this.$http.post(`/api/app/ask/caskQuestioning`, params).then((res) => {
        this.getAnswerList();
        this.$message.success("追问内容已发送");
        this.more_ask = "";
      });
    },
    reApply(item) {
      this.more_ask = "";
      this.detailList.forEach((i, index) => {
        this.$set(i, "show_reAsk", 0);
      });
      this.$set(item, "show_reAsk", 1);
    },
    // 点赞
    clickLike(flag, item) {
      let params = {
        ask_id: this.answer_id,
        comment_id: flag ? item.id : 0,
        user_type: 1,
      };
      this.$pub.clickLike(params).then((res) => {
        if (item.is_like == 0) {
          item.is_like = 1;
          item.like_number++;
        } else {
          item.is_like = 0;
          item.like_number--;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  /deep/ {
    .el-input__inner,
    .el-textarea__inner {
      border-radius: 0;
    }
  }
  .contnt {
    margin-top: 14px;
  }
  .images {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    .el-icon-picture-outline {
      width: 24px;
    }
    .el-icon-picture-outline:before {
      color: rgb(166, 192, 102);
      width: 20px;
      height: 16px;
    }
    .reference {
      cursor: pointer;
    }
  }
  .answer_btn {
    text-align: right;
    button {
      width: 54px;
      height: 24px;
      line-height: 24px;
      padding: 0;
      font-size: 13px;
    }
  }
  .question-box {
    margin-top: 30px;
    font-size: 12px;
    .line {
      border-bottom: 1px solid #eef1f2;
    }
    .question-item {
      margin-top: 15px;
      .item_title {
        display: flex;
        align-items: flex-start;
        // justify-content: space-between;
        flex-wrap: nowrap;
      }
      margin-bottom: 10px;
      position: relative;
      .question-item-left {
        width: 38px;
        height: 38px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #eef1f2;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .question-item-right {
        width: 223px;
        .question-item-name {
          font-size: 14px;
          font-family: PingFangSC-Medium;
          color: #333;
          height: 20px;
          line-height: 20px;
          margin: 0;
          span {
            color: #999;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            padding-left: 30px;
          }
        }
        .created_at {
          color: #999999;
          margin-top: 5px;
          margin-bottom: 13px;
        }
        .answer_state,
        .like_state {
          position: absolute;
          right: 0;
          top: 0;
          color: #333333;
        }
        .no_answer {
          color: $primary_color;
        }
        .question-line-clamp {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .question-item-title {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          color: #333;
          line-height: 1.6;
          margin-bottom: 16px;
        }
      }
    }
    .more_ask {
      margin-top: 15px;
    }
    .tips {
      width: 18px;
      height: 18px;
      vertical-align: middle;
    }
    .question-item-content {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      color: #666;
      line-height: 1.6;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      // text-overflow: ellipsis;
      -webkit-line-clamp: 3;
    }
    .detail_info {
      padding-bottom: 20px;
    }
  }
  .mt {
    margin-top: 68px;
  }
  .child_ask {
    padding: 5px;
    background-color: #fcfbfb;
    .question-item-right {
      width: 210px !important;
    }
  }
}
.w280 {
  width: 280px !important;
}
.detail {
  .back {
    font-size: 12px;
    color: #333333;
    .el-icon-arrow-left {
      font-size: 14px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
  .like_state {
    cursor: pointer;
    color: #999999;
    font-size: 12px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
