<template>
  <div class="play_page">
    <iframe
      class="serve_iframe"
      width="100%"
      height="100vh"
      :src="src"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "PublicCoursePlay",
  components: {},
  data() {
    return {
      src: "",
      time: 0,
      sendTimer: null,
    };
  },
  methods: {
    getTime() {
      let that = this;
      that.sendTimer = setInterval(() => {
        let start_time = localStorage.getItem("start_time");
        if (that.time >= 20) {
          clearTimeout(this.sendTimer);
          that.sendTimer = null;
          that.$message.warning("请登录后查看完整视频");
          that.$router.push({
            name: "Login",
          });
          // localStorage.removeItem('start_time');
        } else {
          that.time = that.$pub.dateDifference(
            start_time,
            new Date().getTime()
          );
        }
      }, 1000);
    },
  },
  created() {
    this.src = this.$route.query.src;
    if (localStorage.getItem("start_time")) {
      this.getTime();
    }
  },
  beforeDestroy() {
    clearInterval(this.sendTimer);
    this.sendTimer = null;
    this.time = 0;
    // localStorage.removeItem('start_time')
  },
};
</script>

<style lang="scss" scoped>
.play_page {
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
  .serve_iframe {
    height: 100%;
  }
}
</style>
