<template>
  <div class="main-container">
    <div style="padding-top:24px;">优惠券可用课程：</div>
    <div class="course-list">
      <CourseItem v-for="course in courses" :key="course.id" :course="course"></CourseItem>
      <div style="clear:both;"></div>
    </div>
    <Empty v-if="courses.length==0" class="empty" title="暂无课程安排，敬请期待！" />
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="limit"
      layout="prev, pager, next, total, jumper"
      :total="total"
      v-if="courses.length>0"
    ></el-pagination>
  </div>
</template>
<script>
import { SearchCouseType } from "@/utils/enums";
import CourseItem from "@/components/CourseItem.vue";

export default {
  name: "Course",
  components: { CourseItem },
  data() {
    return {
      limit: 9,
      page: 1,
      total: 0,
      courses: []
    };
  },
  async created() {
    await this.getCourse();
  },
  methods: {
    async getCourse() {
      let data = await this.$http.get(
        "/api/app/couponGoods?type=1&coupon_id=" + this.$route.query.id,
        {
          page: this.page,
          limit: this.limit
        }
      );
      let courses = data.list;
      courses.forEach(c => {
        c.start_play = new Date(c.start_play_date * 1000).format(
          "MM月dd日 hh:mm"
        );
        c.end_play = new Date(c.end_play_date * 1000).format("MM月dd日 hh:mm");
        c.teachers_list = c.teachers_list.splice(0, 3);
      });
      this.courses = courses;
      this.total = data.total;
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getCourse();
    }
  }
};
</script>
<style lang="scss" scoped>
.empty {
  margin-bottom: 30px;
}

.banner {
  height: calc(100vw * 400 / 1920);
  overflow: hidden;
  .slider-item {
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

/deep/ .el-pagination {
  text-align: center;
  margin: 23px 0 50px 0;
}

/deep/ .el-pagination.is-background .el-pager .number {
  background-color: white;
}

.course-list {
  padding-top: 31px;
}
</style>
