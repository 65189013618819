<template>
  <div>加载中...</div>
</template>
<script>
export default {
  name: "CloudClassNavigation",
  async created() {
    const courseId = this.$route.params.id;
    const courseType = parseInt(this.$route.query.course_type, 10);
    const userId = localStorage.getItem("user_id");
    console.log(userId);

    if (courseType === 4) {
      const data = await this.$http.get(
        `/api/app/courseInfo/basis_id=${courseId}`
      );
      const classId = data.info.class_id;

      if (!classId) {
        return;
      }

      const result = await this.$http.get("/api/app/course/class_url", {
        classId,
      });

      if (result.is_in_the_room) {
        this.$message.error("您已在直播课堂中，请先关闭之前的播放页面");
        return;
      }

      location = result.standardRoomJumpUrl;
      return;
    }
  },
};
</script>
