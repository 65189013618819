<template>
  <div>
    <el-form
      :rules="rules"
      :label-position="labelPosition"
      :model="enrollment"
      ref="enrollmentForm"
      label-width="100px"
    >
      <el-form-item label="姓名" prop="real_name">
        <el-input v-model="enrollment.real_name" />
      </el-form-item>

      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="enrollment.mobile" />
      </el-form-item>

      <el-form-item label="身份证号" prop="identity_card">
        <el-input v-model="enrollment.identity_card" />
      </el-form-item>

      <el-form-item label="学号" prop="student_number">
        <el-input v-model="enrollment.student_number" />
      </el-form-item>

      <el-form-item label="交费平台" prop="payment_channel">
        <el-input v-model="enrollment.payment_channel" />
      </el-form-item>

      <el-form-item label="渠道" prop="channel">
        <el-select
          v-model="enrollment.channel"
          placeholder="您是通过那种渠道了解我们张工教育的？"
          style="width: 100%"
        >
          <el-option label="淘宝" value="淘宝"></el-option>
          <el-option label="土木在线" value="土木在线"></el-option>
          <el-option label="B站" value="B站"></el-option>
          <el-option label="QQ群" value="QQ群"></el-option>
          <el-option label="今日头条" value="今日头条"></el-option>
          <el-option label="朋友推荐" value="朋友推荐"></el-option>
          <el-option label="其它" value="其它"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="地址" prop="address">
        <el-input v-model="enrollment.address" />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()"> 提交 </el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    course_id: {
      type: Number,
      default: 0,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      labelPosition: "right",
      enrollment: {
        real_name: "",
        identity_card: "",
        referrer: "",
        qq_number: "",
        student_number: "",
        address: "",
        payment_channel: "",
        channel: "",
        course_id: 0,
        mobile: "",
      },
      rules: {
        real_name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请填写手机号码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.enrollment.course_id = this.course_id;
    this.enrollment.mobile = this.$store.state.user_info.mobile;
  },
  methods: {
    resetForm() {
      this.$refs.enrollmentForm.resetFields();
    },
    submitForm() {
      this.$refs.enrollmentForm.validate((valid) => {
        if (valid) {
          this.$http
            .post("/api/app/course/enrollments", this.enrollment)
            .then((data) => {
              this.onSuccess(data);

              this.$message({
                message: "保存成功",
                type: "success",
                duration: 1500,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
