<template>
  <div>
    <div
      class="period-header clear"
      :class="{ 'period-header-7': type == 7 }"
      @click="targetPeriod(period)"
    >
      <div
        class="period-title f-left"
        :title="period.periods_title"
        :class="{ 'period-title-10': type == 10 }"
      >
        <span
          class="period-point"
          :class="{ 'period-point-try': period.is_try_see }"
        ></span>
        <span class="period-try" v-if="period.is_try_see">试看</span>
        <span class="width_auto" v-if="type == 5 || type == 8">{{
          period.periods_title
        }}</span>
        <span class="width_auto" v-else-if="type == 10">{{
          period.periods_title
        }}</span>
        <span v-else>{{ period.periods_title }}</span>
      </div>
      <div
        class="f-right period-state"
        v-if="type != 5 && type != 8 && type != 10"
      >
        <span class="live-state" v-if="type == 2 || type == 3">
          <img
            v-if="period.play_type == 1"
            src="@/assets/images/course/not-begin@2x.png"
            style="width: 48px"
          />
          <img
            v-if="period.play_type == 2"
            src="@/assets/images/course/living@2x.png"
            style="width: 64px"
          />
          <img
            v-if="period.play_type == 3"
            src="@/assets/images/course/not-back@2x.png"
            style="width: 48px"
          />
          <img
            v-if="period.play_type == 4"
            src="@/assets/images/course/back@2x.png"
            style="width: 48px"
          />
        </span>
        <span v-if="type == 7">
          <span v-if="period.play_type == 1">未开始</span>
          <span v-else-if="period.play_type == 2">开课中</span>
          <span v-else-if="period.play_type == 3">已结束</span>
        </span>
      </div>
      <div
        class="f-right period-teacher"
        v-if="type != 5 && type != 8 && type != 10"
        :title="
          period.teacher_name +
          (period.start_play ? period.start_play + ' - ' + period.end_play : '')
        "
      >
        {{ period.teacher_name }}
        <span v-if="period.start_play">{{
          period.start_play + " - " + period.end_play
        }}</span>
      </div>
    </div>
    <div class="period-files" v-if="period.files.length">
      <div
        v-for="(file, index) in period.files"
        class="period-file"
        :class="{ 'period-file-last': index == period.files.length - 1 }"
        :key="file.file_url"
      >
        <img src="@/assets/images/doc.png" class="file-type" />
        <a
          href="javascript:void(0)"
          @click="visitFile(file.datum_id, index + 1)"
          style="word-break: break-all"
          >{{ file.file_name }}</a
        >
      </div>
    </div>
    <el-dialog
      title="查看课时内容"
      :visible.sync="showContentDialog"
      :center="true"
      width="700px"
    >
      <div v-html="chapter_content" class="rich-text rich-text-img-width"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showContentDialog = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "CoursePeriod",
  props: {
    period: {
      type: Object,
    },
    course: {
      type: Object,
    },
  },
  data() {
    return {
      type: 0,
      showContentDialog: false,
      chapter_content: "",
    };
  },
  created() {
    this.type = this.course.course_type;
  },
  methods: {
    async targetVideo(period) {
      if (this.course.is_join_study == 0 && period.is_try_see == 0) {
        await this.$info("请您先报名");
        return;
      }
      let course_type = this.course.course_type;
      if (course_type == 2 || course_type == 3) {
        if (period.mock_live_url) {
          const url = period.mock_live_url.replace("http://", "https://");
          this.$router.push({
            name: "Video",
            query: {
              course_id: this.course_id,
              period_id: period.id,
              video_id: period.video_id,
              course_type: this.course_type,
              id: period.id,
              parent_id: period.parent_id,
              mlurl: url,
            },
          });

          return;
        }

        if (period.cloud_class_type === "plaso") {
          const meetingId = `${period.course_basis_id}_${period.id}`;
          const routeData = this.$router.resolve({
            name: "CloudClass",
            query: { meetingId },
          });

          window.open(routeData.href, "_blank");
          return;
        }

        this.$livePlay(this.course.id, course_type, period.id);
      } else if (course_type == 5 || course_type == 8) {
        await this.$http.get(
          "/api/app/getPlayToken/video_id=" +
            period.video_id +
            "/course_id=" +
            this.course.id,
          {},
          true
        );
        this.$router.push({
          name: "Video",
          query: {
            course_id: this.course.id,
            period_id: period.id,
            video_id: period.video_id,
            course_type: this.course.course_type,
            id: period.id,
            parent_id: period.parent_id,
          },
        });
      }
    },
    async visitFile(id, index) {
      let data = await this.$http.get(
        "/api/app/chapterDatum/" + id + "/" + index
      );
      window.open(data.file_url);
    },
    async targetContent(id) {
      let data = await this.$http.get("/api/app/chapterInfo/" + id);
      this.chapter_content = data.course_content;
      this.showContentDialog = true;
    },
    async targetPeriod(period) {
      if (this.type == 10) {
        await this.targetContent(period.id);
      } else {
        await this.targetVideo(period);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.period-header:not(.period-header-7) {
  cursor: pointer;
  &:hover {
    .period-title,
    .period-teacher,
    .period-state {
      color: $primary-color;
    }
  }
}

.period-title {
  font-size: 16px;
  color: #595959;
  overflow: hidden;
  span {
    float: left;
    &:last-child {
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .width_auto {
    width: 680px !important;
  }
  .period-point {
    margin-top: 7px;
  }
  &.period-title-10 {
    width: auto;
  }
}

.period-try {
  font-size: 12px;
  color: white;
  display: inline-block;
  width: 44px;
  height: 17px;
  line-height: 17px;
  text-align: center;
  background-image: url("~@/assets/images/course/try-back@2x.png");
  background-size: 100% 100%;
  margin-right: 7px;
  margin-top: 2px;
}

.period-point {
  width: 5px;
  height: 5px;
  background-color: $primary-color;
  border-radius: 100%;
  display: inline-block;
  margin-right: 18px;
  &.period-point-try {
    margin-right: 8px;
  }
}

.period-files {
  padding-top: 11px;
  a:hover {
    color: $primary-color;
  }
}

.period-file a {
  font-size: 14px;
  color: rgba(89, 89, 89, 1);
}

.period-file {
  padding-left: 10px;
  margin-bottom: 8px;
  font-weight: 400;
  &.period-file-last {
    margin-bottom: 0;
  }
}

.file-type {
  margin-right: 5px;
  margin-left: 2px;
}

.period-teacher {
  font-size: 14px;
  color: rgba(183, 183, 183, 1);
  font-weight: 400;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
  text-align: right;
}

.period-state {
  color: rgba(183, 183, 183, 1);
  width: 137px;
  text-align: right;
}

.live-state {
  cursor: pointer;
}

.period-living {
  color: $primary-color;
}
</style>
