<template>
  <div>
    <ul v-if="display_style == 1">
      <li
        class="chapter-item"
        v-for="chapter in chapters"
        :key="chapter.id"
        :class="{ 'chapter-expand': chapterExpand[chapter.id] }"
      >
        <div class="chapter-title" @click="toggleExpand(chapter.id)">
          <i class="el-icon-caret-bottom" v-if="chapterExpand[chapter.id]"></i>
          <i class="el-icon-caret-right" v-else></i>
          {{ chapter.title }}
        </div>
        <div
          class="chapter-content"
          v-for="period in chapter.child"
          :key="period.id"
          :class="{ current: currentPeriod == period.id }"
        >
          <CoursePeriod :period="period" :course="course"></CoursePeriod>
        </div>
      </li>
    </ul>
    <ul v-else>
      <div
        class="chapter-content chapter-content-alone"
        :class="{ current: currentPeriod == period.id }"
        v-for="period in chapters"
        :key="period.id"
        style="display: block"
      >
        <CoursePeriod :period="period" :course="course"></CoursePeriod>
      </div>
    </ul>
  </div>
</template>
<script>
import CoursePeriod from "./CoursePeriod.vue";

export default {
  name: "CourseChapter",
  components: {
    CoursePeriod,
  },
  data() {
    return {
      id: "",
      type: 0,
      chapters: [],
      chapterExpand: {},
      display_style: 0,
    };
  },
  props: {
    course: {
      type: Object,
    },
    currentPeriod: {
      type: String,
    },
  },
  async created() {
    await this.getData();
  },
  watch: {
    course: async function () {
      await this.getData();
    },
  },
  methods: {
    async getData() {
      this.id = this.course.id;
      this.type = this.course.course_type;
      await this.getChapter();
    },
    formatPeriod(period) {
      let teacherNames = [];
      if (period.teachers) {
        period.teachers.forEach((t) => {
          teacherNames.push(t.teacher_name);
        });
      }
      period.teacher_name = teacherNames.join(",");
      let files = [];
      if (period.datum && period.datum.length > 0) {
        let datum_id = period.datum[0].datum_id;
        let fileNames = period.datum[0].file_name.split(";");
        for (let i = 0; i < fileNames.length; i++) {
          let fileName = fileNames[i];
          files.push({ file_name: fileName, datum_id: datum_id });
        }
      }
      period.files = files;
    },
    async getChapter() {
      if (!this.id) {
        return;
      }
      if (this.type == 9 || this.course.type == 4) {
        return;
      }
      let chapters = await this.$http.post("/api/app/courseChapter", {
        id: this.id,
      });
      if (chapters.length > 0) {
        if (!chapters[0].periods_title) {
          this.display_style = 1;
          chapters.forEach((c) => {
            if (!c.child) {
              return;
            }
            c.child.forEach((child) => {
              this.formatPeriod(child);
            });
            this.$set(this.chapterExpand, c.id, 0);
          });
        } else {
          chapters = chapters.filter((c) => c.parent_id != 0);
          chapters.forEach((c) => {
            this.formatPeriod(c);
          });
        }
      }
      this.chapters = chapters;
    },
    toggleExpand(id) {
      if (this.chapterExpand[id] == 0) {
        this.chapterExpand[id] = 1;
      } else {
        this.chapterExpand[id] = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.chapter-title {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}

.chapter-title i {
  margin-right: 8px;
  color: #bfbfbf;
}

.chapter-expand i {
  color: $primary-color;
  margin-bottom: 16px;
}

.chapter-content {
  display: none;
  padding-left: 30px;
  margin-bottom: 12px;
  &.chapter-content-alone {
    padding-left: 0;
    margin-bottom: 24px;
    &:first-child {
      padding-top: 24px;
    }
    &:last-child {
      padding-bottom: 24px;
    }
    /deep/ {
      .period-point {
        margin-right: 10px;
      }
      .period-file {
        padding-left: 14px;
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.chapter-expand .chapter-content {
  display: block;
}

.chapter-item {
  border-bottom: 1px dashed #e9e9e9;
  padding: 24px 0;
}

.chapter-item:last-child {
  border-bottom: none;
  padding-bottom: 32px;
}
</style>
